import React from 'react';
import ImageProfile from '../images/photo-profile.png';
import { Link } from 'react-router-dom';
import SocialMedia from './social-media';

const HomeSupport = () => {
  return (
    <>
      <section className='py-5 w-100 lg:h-[calc(100vh_-_150px)] bg-primary flex items-center'>
        <div className="px-3 lg:px-0 max-w-2xl lg:max-w-5xl mx-auto grid gap-5 grid-cols-1 lg:grid-cols-[1fr_2fr] place-items-center">
          <figure className='overflow-hidden rounded-full bg-accent max-w-[250px] lg:max-w-none'>
            <img alt="profile jesus" src={ImageProfile} loading='eager' />
          </figure>
          <div className='w-full flex justify-center flex-col'>
            <h1 className='text-tertiary text-5xl lg:text-8xl font-bold text-center lg:text-right inline-block font-oswald'>Jesus Uzcategui</h1>
            <h2 className='text-accent mt-3 text-3xl lg:text-5xl font-bold text-center lg:text-right inline-block font-dm-sans'>Fullstack web developer</h2>
            <p className='text-alternative mt-3 text-lg lg:text-xl text-center lg:text-right inline-block font-dm-sans'>
              ¡Saludos! Soy Jesús, tu guía digital. Como consultor, me especializo en plataformas líderes como <strong>Shopify, Wordpress, Joomla</strong> y más. Con experiencia en tecnologías avanzadas como <strong>React, PHP, Node.js, Vue y Angular</strong>, estoy aquí para potenciar tu presencia en línea. Descubre cómo puedo ayudarte a alcanzar tus objetivos digitales.
            </p>
            <div className="flex justify-center lg:justify-end">
              <div className="mt-5 lg:max-w-xs">
                <SocialMedia />
              </div>
            </div>
            <div className="flex justify-center lg:justify-end">
              <Link className='mt-5 transition-all delay-100 ease-in-out hover:bg-accent hover:text-alternative bg-tertiary text-primary font-bold text-center font-lg rounded-sm p-3 inline-block max-w-max font-dm-sans' reloadDocument to="/contact">Comunicate conmigo</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeSupport;