import React from 'react';
import { Link } from 'react-router-dom';

const ComponentPagination = ({ links }) => {
  return (
    <>
      <nav aria-label="Page navigation example">
        <ul className="flex items-center -space-x-px h-8 text-sm">
          {Array.isArray(links) && (links.map(({ page, label, current }) => <li key={`tutorials__${page}`}><Link className={`flex items-center justify-center px-3 h-8 leading-tight  ${(current) ? `text-white bg-gray-500 border-gray-600 hover:bg-gray-700` : `text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}`} to={`?page=${page}`}>{label}</Link></li>))}
        </ul>
      </nav>
    </>
  );
};

export default ComponentPagination;