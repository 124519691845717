import React from 'react';
import { useLoaderData } from 'react-router-dom';
import ComponentPagination from '../../components/pagination';
import ComponentCard from '../../components/card';

const PageProjects = () => {
  const { Projects, links } = useLoaderData();
  console.log(Projects);


  return (
    <>
      <main className='px-3 lg:px-0 w-full max-w-2xl md:max-w-5xl mx-auto py-3'>
        <header className='mb-10'>
          <h1 className='text-center text-6xl lg:text-7xl font-bold'>Proyectos</h1>
        </header>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 auto-rows-fr gap-4'>
          {Projects && Projects.map(({ fields: { title, slug, logoBussiness }, sys: { id } }) => {
            const url = logoBussiness?.fields?.file?.url || ''; // Verifica si logoBussiness existe antes de destructurar
            return (
              <div key={id}>
                <ComponentCard title={title} image={url} slug={`/projects/${slug}`} paddingHack={true} />
              </div>
            );
          })}
        </div>
        <ComponentPagination links={links} />
      </main>
    </>
  );
};

export default PageProjects;