import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { marked } from 'marked';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/sidebar';

const PageTutorialSingle = () => {
  const { tutorial: { fields: { title, slug, contentMark, videoYoutube, image: { fields: { file: { url } } } } } } = useLoaderData();

  const content = marked(contentMark);

  return (
    <>
      <Helmet>
        <title>{ title } | Jesus Uzcátegui</title>
        <link rel="canonical" href={`https://jesusuzcategui.me/tutorials/${slug}`} />
      </Helmet>
      <main className='px-3 lg:px-0 w-full max-w-2lg md:max-w-5xl mx-auto py-3'>
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-5">
          <article className='w-full'>
            <figure className='relative pb-[56.22%] overflow-hidden w-full rounded-sm mb-5'>
              <img className='absolute w-full h-full object-cover top-0 left-0' src={url} alt={title} />
            </figure>
            <h1 className='text-left text-primary font-bold text-3xl lg:text-5xl mb-10 font-oswald'>
              {title}
            </h1>
            {(videoYoutube) && (
              <div className='relative pb-[56.22%] overflow-hidden w-full rounded-sm mb-5'>
                <iframe
                  title={ title }
                  className='absolute w-full h-full object-cover top-0 left-0'
                  src={videoYoutube}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen>  
                </iframe>
              </div>
            )}
            <div className='w-full content-post'>
              <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>            
          </article>
          <Sidebar />
        </div>
      </main>
      <pre></pre>
    </>
  );
};

export default PageTutorialSingle;