import { getHeader } from "./services/contentful/common";
import { getTutorials, getTutorial } from "./services/contentful/tutoriales";
import { getCourses, getCourse } from "./services/contentful/courses";
import { getProjects, getProject } from "./services/contentful/projects";

import LayoutMain from "./layouts/main";
import PageHome from "./pages/home";
import PageTutorials from "./pages/tutorials";
import PageCourses from "./pages/courses";
import PageCourseSingle from "./pages/courses/single";
import PageNoFound from "./pages/404";
import PageContact from "./pages/contact";
import PageConfirmForm from './pages/contact-confirm';
import PageProjects from "./pages/projects";
import PageProjectSingle from "./pages/projects/single";

import { createBrowserRouter } from "react-router-dom";
import PageTutorialSingle from "./pages/tutorials/single";

export default function routes(){
  return createBrowserRouter([
    {
      path: '/',
      element: <LayoutMain />,
      errorElement: <PageNoFound />,
      loader: getHeader,
      children: [
        {
          path: '',
          element: <PageHome />
        },
        {
          path: 'tutorials',
          element: <PageTutorials />,
          loader: getTutorials
        },
        {
          path: 'tutorials/:slug',
          element: <PageTutorialSingle />,
          loader: getTutorial
        },
        {
          path: 'courses',
          element: <PageCourses />,
          loader: getCourses
        },
        {
          path: 'courses/:slug',
          element: <PageCourseSingle />,
          loader: getCourse
        },
        {
          path: 'projects',
          element: <PageProjects />,
          loader: getProjects
        },
        {
          path: 'projects/:slug',
          element: <PageProjectSingle />,
          loader: getProject
        },
        {
          path: 'contact',
          element: <PageContact />
        },
        {
          path: 'confirm-send',
          element: <PageConfirmForm />
        }
      ]
    }
  ]);
};