import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { Accordion } from 'flowbite-react';
import { marked } from 'marked';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/sidebar';

const PageCourseSingle = () => {
  const { course: { fields: { title, chapter, contentMark, slug, mainImage: { fields: { file: { url } } } } } } = useLoaderData();

  return (
    <>
      <Helmet>
        <title>{ title } | Jesus Uzcátegui</title>
        <link rel="canonical" href={`https://jesusuzcategui.me/tutorials/${slug}`} />
      </Helmet>
      <main className='px-3 lg:px-0 w-full max-w-2lg md:max-w-5xl mx-auto py-3'>
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-5">
          <article className='content-post'>
            <figure className='relative pb-[56.22%] overflow-hidden w-full rounded-sm mb-5'>
              <img className='absolute w-full h-full object-cover top-0 left-0' src={url} alt={title} />
            </figure>
            <h1 className='text-left text-primary font-bold text-3xl lg:text-5xl mb-10 font-oswald'>{title}</h1>

            {(contentMark) && (<div className='w-full my-3' dangerouslySetInnerHTML={{__html: marked(contentMark) }}></div>)}

            {(chapter.length > 0) && (
              <Accordion>
                {(chapter.map( ({ sys: { id }, fields: { title, shortDescription, content:longCont, urlVideo, image: { fields: { file: { url } } } } }) => (
                  <Accordion.Panel key={id}>
                    <Accordion.Title className='font-oswald text-xl font-medium'>{ title }</Accordion.Title>
                    <Accordion.Content>
                      <div className='grid grid-cols-1 lg:grid-cols-[1fr_2fr] gap-3'>
                        <div className=''>
                          <figure className='relative pb-[56.22%] overflow-hidden w-full rounded-sm mb-5'>
                            <img className='absolute w-full h-full object-cover top-0 left-0' src={url} alt={title} />
                          </figure>
                        </div>
                        <div className='mb-5 lg:mb-0'>
                          <div className='' dangerouslySetInnerHTML={{__html: marked(shortDescription) }}></div>
                        </div>
                      </div>
                      {(urlVideo) && (
                        <div className='w-full'>
                          <div className='relative pb-[56.22%] overflow-hidden w-full rounded-sm mb-5'>
                            <iframe
                              title={ title }
                              className='absolute w-full h-full object-cover top-0 left-0'
                              src={urlVideo}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen>  
                            </iframe>
                          </div>
                        </div>
                      )}
                      
                      {(longCont) && (<div className='w-full mt-3' dangerouslySetInnerHTML={{__html: marked(longCont) }}></div>)}
                    </Accordion.Content>
                  </Accordion.Panel>
                ) ))}
              </Accordion>
            )}
            
          </article>
          <Sidebar />
        </div>
      </main>
      <pre></pre>
    </>
  );
};

export default PageCourseSingle;