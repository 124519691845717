import React, { useState } from 'react';
import { useLoaderData, Link, useLocation } from 'react-router-dom';
import Drawer from './Drawer';

import Logo from '../images/logo.png';

const ComponentHeader = () => {

  const { header } = useLoaderData();
  const [headerLayout] = header;
  const { fields: { menuItems } } = headerLayout;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();

  const { pathname } = location;

  const validateRoute = (route) => {
    const split = pathname.split('/');
    const splitR = route.split('/');

    let active = false;
    if( split[1] === splitR[1] ){
      active = true;
    }

    return active;
  };

  return (
    <>
      <Drawer
        validateRoute={validateRoute}
        items={ menuItems } 
        open={ drawerOpen }
        setOpen={ setDrawerOpen }
      />
      <header className='w-full bg-primary'>
        <main className='px-3 lg:px-0 max-w-2lg md:max-w-5xl mx-auto flex justify-between items-center py-3'>
          <div className="flex items-center justify-center lg:hidden">
            <button onClick={() => setDrawerOpen(true) } className='block p-3 rounded-sm bg-alternative text-accent'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg>
            </button>
          </div>
          <Link className='w-[250px] lg:w-[280px]' reloadDocument to="/">
            <img src={Logo} loading='eager' alt='Jesus Uzcategui' />
          </Link>
          <nav className='hidden lg:block'>
            <ul className='flex gap-3'>
              {( menuItems?.length > 0 ) && (menuItems?.map(({ fields: { href, text }, sys: { id } }) => {
                return (
                  <li key={ id }>
                    <Link
                      reloadDocument
                      onClick={() => setDrawerOpen(false)}
                      className={`text-white font-dm-sans font-bold text-xl border-transparent hover:border-white border-[1px] rounded-sm inline-block py-1 px-2 transition-all delay-200 ease-in-out ${validateRoute(href) ? 'border-white' : ''}`}
                      to={ href }>
                        { text }
                    </Link>
                  </li>
                );
              }))}
            </ul>
          </nav>
        </main>
      </header>
    </>
  );
};

export default ComponentHeader;