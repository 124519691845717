import React from 'react';
import HomeSupport from '../components/HomeSupport';
import HomeServices from '../components/HomeServices';
import AboutMe from '../components/about-me';


const PageHome = () => {
  return (
    <>
      <HomeSupport/>
      
      <HomeServices />

      <AboutMe />
    </>
  );
};

export default PageHome;