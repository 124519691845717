import React from 'react';
import { Link } from 'react-router-dom';
import IconEccommerce from '../images/online-shopping.png';
import IconSupport from '../images/technical-support.png';
import IconDevelopment from '../images/clean-coding.png';

const HomeServices = () => {
  return (
    <>
      <section className='py-8 w-100 bg-alternative flex items-center'>
        <div className="px-3 lg:px-0 max-w-2xl lg:max-w-5xl mx-auto flex items-center flex-col">
          <div className='w-full flex justify-center flex-col'>
            <h2 className='text-primary text-4xl lg:text-6xl font-bold text-center inline-block font-oswald'>Te ofrezco</h2>
            <h2 className='text-accent mt-3 text-2xl lg:text-3xl font-medium text-center inline-block font-dm-sans'>Ayudarte a crecer digitalmente</h2>
            <p className='text-primary mt-3 text-lg lg:text-xl text-center inline-block mb-5 font-dm-sans'>
              Como desarrollador web full stack, ofrezco soluciones completas para la creación, mejora y optimización de sitios en Shopify, WordPress y Joomla. Mi experiencia incluye desarrollo en React, Vue, Angular, PHP, Laravel, Node.js, Python y más. Desde el diseño hasta el backend, estoy aquí para potenciar tu presencia digital con soluciones eficientes y de calidad.
            </p>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
              <article className='bg-primary p-5 rounded-sm flex flex-col items-center'>
                <figure className='w-[100px] h-[100px] bg-accent rounded-full overflow-hidden mx-auto mb-3 p-5 flex justify-center items-center'>
                  <img className='object-contain w-full h-full' src={IconEccommerce} alt="icon eccommerce" />
                </figure>
                <h4 className='text-center text-alternative font-bold text-xl inline-block mb-3 font-oswald'>Desarrollo de ecommerce</h4>
                <p className='text-alternative font-medium text-center inline-block text-lg font-dm-sans'>Creación y mejora de tu tienda en linea basada en Wordpress, Shopify o la técnologia que desees, que hará crecer tu alcance como comercio.</p>
              </article>
              <article className='bg-primary p-3 rounded-sm flex flex-col items-center'>
                <figure className='w-[100px] h-[100px] bg-accent rounded-full overflow-hidden mx-auto mb-3 p-5 flex justify-center items-center'>
                  <img className='object-contain w-full h-full' src={IconDevelopment} alt="icon development" />
                </figure>
                <h4 className='text-center text-alternative font-bold text-xl inline-block mb-3 font-oswald'>Desarrollo personalizado</h4>
                <p className='text-alternative font-medium text-center inline-block text-lg font-dm-sans'>Creación de sistemas y aplicaciones personalizadas para cualquier tipo de nicho deseado.</p>
              </article>
              <article className='bg-primary p-3 rounded-sm flex flex-col items-center'>
                <figure className='w-[100px] h-[100px] bg-accent rounded-full overflow-hidden mx-auto mb-3 p-5 flex justify-center items-center'>
                  <img className='object-contain w-full h-full' src={IconSupport} alt="icon support" />
                </figure>
                <h4 className='text-center text-alternative font-bold text-xl inline-block mb-3 font-oswald'>Soporte técnico</h4>
                <p className='text-alternative font-medium text-center inline-block text-lg font-dm-sans'>Me conectaré contigo y te ayudaré a solventar cualquier eventualidad de tu sitio web, sistema o aplicación.</p>
              </article>
            </div>
            <div className="flex justify-center">
              <Link className='mt-5 transition-all delay-100 ease-in-out hover:bg-accent hover:text-alternative bg-tertiary text-primary font-bold text-center font-lg rounded-sm p-3 inline-block max-w-max' reloadDocument to="/contact">Comunicate conmigo</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;