import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

function Gallery({ images }) {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    
    return (
        <div className="App">
            
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                elementClassNames={`grid grid-cols-2 lg:grid-cols-4 gap-2`}
                
            >
                {(images.map(({fields: { file: { url } }}) => (
                    <a href={url}>
                        <img alt="img1" src={url} />
                    </a>
                )))}
            </LightGallery>
        </div>
    );
}


export default Gallery;