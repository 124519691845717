import React from "react";

const SocialMedia = () => {
  return (
    <>
      <h3 className='text-2xl font-bold inline-block mb-3 text-alternative text-center w-full font-oswald'>Sígueme</h3>
      <div className='grid grid-cols-4 gap-3 mb-5 place-items-center place-content-center'>
        <a className='bg-accent text-white cursor-pointer rounded-sm hover:bg-secondary transition-all p-3 flex justify-center items-center' rel="noreferrer" href="https://www.facebook.com/devjsus/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook-filled" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 2a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -.883 .993l-.117 .007h-3v1h3a1 1 0 0 1 .991 1.131l-.02 .112l-1 4a1 1 0 0 1 -.858 .75l-.113 .007h-2v6a1 1 0 0 1 -.883 .993l-.117 .007h-4a1 1 0 0 1 -.993 -.883l-.007 -.117v-6h-2a1 1 0 0 1 -.993 -.883l-.007 -.117v-4a1 1 0 0 1 .883 -.993l.117 -.007h2v-1a6 6 0 0 1 5.775 -5.996l.225 -.004h3z" strokeWidth="0" fill="currentColor" /></svg>
        </a>
        <a className='bg-accent text-white cursor-pointer rounded-sm hover:bg-secondary transition-all p-3 flex justify-center items-center' rel="noreferrer" href="https://www.instagram.com/devjsus/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
        </a>
        <a className='bg-accent text-white cursor-pointer rounded-sm hover:bg-secondary transition-all p-3 flex justify-center items-center' rel="noreferrer" href="https://www.youtube.com/channel/UCXJA6wXh2GJxrjkUQa_XgRw" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-youtube-filled" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 3a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-12a5 5 0 0 1 -5 -5v-8a5 5 0 0 1 5 -5zm-9 6v6a1 1 0 0 0 1.514 .857l5 -3a1 1 0 0 0 0 -1.714l-5 -3a1 1 0 0 0 -1.514 .857z" strokeWidth="0" fill="currentColor" /></svg>
        </a>
        <a className='bg-accent text-white cursor-pointer rounded-sm hover:bg-secondary transition-all p-3 flex justify-center items-center' rel="noreferrer" href="https://www.linkedin.com/in/jes%C3%BAs-uzc%C3%A1tegui-15a317202/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M8 11l0 5" /><path d="M8 8l0 .01" /><path d="M12 16l0 -5" /><path d="M16 16v-3a2 2 0 0 0 -4 0" /></svg>
        </a>
      </div>
    </>
  );
};

export default SocialMedia;