import React from 'react';
import { useLoaderData } from 'react-router-dom';
import ComponentPagination from '../../components/pagination';
import ComponentCard from '../../components/card';

const PageTutorials = () => {
  const { tutorials, links } = useLoaderData();
  return (
    <>
      <main className='px-3 lg:px-0 w-full max-w-2xl md:max-w-5xl mx-auto py-3'>
      <header className='mb-10'>
          <h1 className='text-center text-6xl lg:text-7xl font-bold'>Tutoriales</h1>
        </header>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 auto-rows-fr gap-4'>
          {(tutorials) && (tutorials.map(({ fields: { title, slug, image: { fields: { file: { url } } } }, sys: { id } }) => (
            <div key={ id }>
              <ComponentCard title={title} slug={`/tutorials/${slug}`} image={url}  />
            </div>)
          ))}
        </div>
        <ComponentPagination links={ links } />
      </main>
    </>
  );
};

export default PageTutorials;