import React from 'react';
import Mailsend from '../images/Mail-sent.jpg';

const PageConfirmForm = () => {
  return (
    <>
      <main className='w-full h-[calc(100vh_-_120px)]'>
        <div className='w-full h-full grid grid-cols-1 lg:grid-cols-2 justify-between'>
          <div className='hidden lg:h-full w-full bg-white lg:flex items-center justify-center lg:p-20'>
            <img className='w-full h-full object-contain object-top' src={Mailsend} alt="Mail send" />
          </div>
          <div className='lg:h-full flex flex-col items-center justify-center bg-secondary text-white'>
            <div className="w-full">
              <h1 className='text-6xl text-center font-bold mb-5'>Gracias por contactarme</h1>
              <p className='text-2xl text-center italic font-bold'>pronto estare respondiendo tus inquitudes.</p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PageConfirmForm;