import React from "react";
import { Link } from "react-router-dom";

const ComponentCard = ({ title, image, slug, paddingHack }) => {
  return (
    <>
      <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 h-full">
        {(image) && (<Link reloadDocument to={slug}>
          {(paddingHack) && (<div className="p-5 block"><div className={`rounded-t-lg relative overflow-hidden pb-[30%]`}>
            <img
              className={`rounded-t-lg absolute top-0 left-0 w-full h-full object-contain`}
              src={ image }
              alt={ title } />
          </div></div>) || (<img
              className={`rounded-t-lg object-cover`}
              src={ image }
              alt={ title } />)}
        </Link>)}
        <div className="p-5">
          <Link reloadDocument to={slug}>
            <h5 className="mb-2 text-xl lg:text-xl text-center font-bold tracking-tight text-primary dark:text-white font-dm-sans">
              { title }
            </h5>
          </Link>          
        </div>
      </div>
    </>
  );
};

export default ComponentCard;
