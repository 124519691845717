import React from 'react';
import { useLoaderData } from 'react-router-dom';
import ComponentPagination from '../../components/pagination';
import ComponentCard from '../../components/card';

const PageCourses = () => {
  const { courses, links } = useLoaderData();

  return (
    <>
      <main className='px-3 lg:px-0 w-full max-w-2xl md:max-w-5xl mx-auto py-3'>
      <header className='mb-10'>
          <h1 className='text-center text-6xl lg:text-7xl font-bold'>Cursos</h1>
        </header>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 auto-rows-fr gap-4'>
          {(courses) && (courses.map(({ fields: { title, slug, mainImage: { fields: { file: { url } } } }, sys: { id } }) => {
            return (
              <div key={id}>
                <ComponentCard title={ title } image={ url } slug={`/courses/${slug}`} />
              </div>
            );
          }))}
        </div>
        <ComponentPagination links={ links } />
      </main>
    </>
  );
};

export default PageCourses;