import React from 'react';

const ComponentFooter = () => {
  return (
    <>
      <div className="w-full bg-primary flex justify-center py-5">
        <p className="text-center font-bold text-alternative font-md">Jesus Uzcategui - 2023 | Powered by me with love.</p>
      </div>
    </>
  );
};

export default ComponentFooter;