import React from "react";

const SkillsProjects = ({ skills }) => {
    return (
        <>
            <div className="grid grid-cols-4 lg:grid-cols-6 gap-10">
                {(Array.isArray(skills) && skills.map(({ fields: { titleskill, experience, logo: { fields: { file: { url } } } } }) => (
                    <div className="flex flex-col items-center">
                        <figure className="relative w-full pb-[100%] overflow-hidden">
                            <img className="absolute top-0 left-0 w-full h-full object-contain " src={ url } alt={ titleskill } />
                        </figure>
                        <span className="text-center font-medium text-md flex mt-3">{ titleskill }</span>
                    </div>
                )))}
            </div>
        </>
    );
};

export default SkillsProjects;