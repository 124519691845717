import React from 'react';
import { Outlet } from "react-router-dom";
import ComponentHeader from '../components/header';
import ComponentFooter from '../components/footer';

const LayoutMain = () => {
  return (
    <>
      <ComponentHeader />
      <main>
        <Outlet />
      </main>
      <ComponentFooter />
    </>
  );
};

export default LayoutMain;