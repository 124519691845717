import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { marked } from 'marked';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/sidebar';
import Gallery from '../../components/gallery';
import SkillsProjects from '../../components/skills-projects';

const PageProjectSingle = () => {
  const { Project: { fields: { title, slug, logoBussiness, isCollaborated, colaborators, content, image, skills, link } } } = useLoaderData();
  const urlLogo = logoBussiness?.fields?.file?.url || undefined;
  const partner = colaborators?.fields || undefined;

  return (
    <>
      <Helmet>
        <title>{title} | Jesus Uzcátegui</title>
        <link rel="canonical" href={`https://jesusuzcategui.me/tutorials/${slug}`} />
      </Helmet>
      <main className='px-3 lg:px-0 w-full max-w-2lg md:max-w-5xl mx-auto py-3'>
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-5">
          <article className='content-post'>

            <header className='relative w-full mb-10 bg-white'>
              <div className='w-full'>
                {(urlLogo) && (<figure className='relative pb-[40%] overflow-hidden'>
                  <img src={urlLogo} className='absolute top-0 left-0 object-contain w-full h-full' />
                </figure>) || (<div>&nbsp;</div>)}
              </div>
              <div className='flex items-center justify-between gap-5'>
                <h1 className='text-left text-primary font-bold text-3xl lg:text-5xl font-oswald mb-0 !leading-[normal]'>{title}</h1>
                {(link) && (<a target='_blank' href={ link } className="!text-white flex gap-3 w-48 justify-center text-center bg-slate-400 hover:bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">
                  Visitar sitio
                  <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M8.64 4.737A7.97 7.97 0 0 1 12 4a7.997 7.997 0 0 1 6.933 4.006h-.738c-.65 0-1.177.25-1.177.9 0 .33 0 2.04-2.026 2.008-1.972 0-1.972-1.732-1.972-2.008 0-1.429-.787-1.65-1.752-1.923-.374-.105-.774-.218-1.166-.411-1.004-.497-1.347-1.183-1.461-1.835ZM6 4a10.06 10.06 0 0 0-2.812 3.27A9.956 9.956 0 0 0 2 12c0 5.289 4.106 9.619 9.304 9.976l.054.004a10.12 10.12 0 0 0 1.155.007h.002a10.024 10.024 0 0 0 1.5-.19 9.925 9.925 0 0 0 2.259-.754 10.041 10.041 0 0 0 4.987-5.263A9.917 9.917 0 0 0 22 12a10.025 10.025 0 0 0-.315-2.5A10.001 10.001 0 0 0 12 2a9.964 9.964 0 0 0-6 2Zm13.372 11.113a2.575 2.575 0 0 0-.75-.112h-.217A3.405 3.405 0 0 0 15 18.405v1.014a8.027 8.027 0 0 0 4.372-4.307ZM12.114 20H12A8 8 0 0 1 5.1 7.95c.95.541 1.421 1.537 1.835 2.415.209.441.403.853.637 1.162.54.712 1.063 1.019 1.591 1.328.52.305 1.047.613 1.6 1.316 1.44 1.825 1.419 4.366 1.35 5.828Z" clip-rule="evenodd" />
                  </svg>

                </a>)}
              </div>
              {(isCollaborated) && (partner) && (
                <div className='w-full'>
                  <p className='text-sm text-slate-400'>En colaboración con:</p>
                  <div className='grid grid-cols-1 lg:grid-cols-[50px_auto] gap-3'>
                    {(partner?.logo) && (<figure className='relative pb-[100%] overflow-hidden'>
                      <img src={partner?.logo?.fields?.file?.url} className='absolute top-0 left-0 object-contain w-full h-full' />
                    </figure>) || (<div>&nbsp;</div>)}
                    <h3 className='flex gap-1 items-center text-left text-primary font-regular text-xl lg:text-2xl font-oswald mb-0 !leading-[normal]'>
                      {partner?.title}
                      <a href={partner?.url} target='_blank'>
                        <svg className="w-6 h-6 text-gray-400 hover:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
                        </svg>
                      </a>
                    </h3>
                  </div>
                </div>
              )}
            </header>

            {(content) && (<div className='w-full my-3' dangerouslySetInnerHTML={{ __html: marked(content) }}></div>)}

            {(skills) && (
              <div className=''>
                <h4 className='text-center text-2xl lg:text-5xl font-bold my-10 font-oswald'>Técnologias usadas</h4>
                <SkillsProjects skills={skills} />
              </div>
            )}

            {(image) && (
              <div className='mb-10'>
                <h4 className='text-center text-2xl lg:text-5xl font-bold my-10 font-oswald'>Galería de imagenes</h4>
                <Gallery images={image} />
              </div>
            )}

          </article>
          <Sidebar />
        </div>
      </main>
      <pre></pre>
    </>
  );
};

export default PageProjectSingle;