import React from 'react';
import { Link, useRouteError } from 'react-router-dom';

const PageNoFound = () => {
  const { status, statusText } = useRouteError();

  return (
    <>
      <main className='flex justify-center flex-col items-center w-full h-screen py-3 bg-primary'>
        <div className='max-w-sm md:max-w-5xl mx-auto'>
          <h1 className='text-center text-alternative text-9xl font-bold'>{ status }</h1>
          <p className='text-center text-alternative text-5xl'>{ statusText }</p>
          <div className='mx-auto my-5 flex justify-center w-full'>
            <Link to='/' className='bg-secondary text-alternative font-bold text-center rounded-sm px-3 py-3 inline-block'>Volver a inicio</Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default PageNoFound;