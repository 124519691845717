import React from 'react';
import Skills from './skills';

const AboutMe = () => {
  return (
    <>
      <section className='w-full'>
        <div className='px-3 pb-8 lg:px-0 max-w-2xl lg:max-w-5xl mx-auto'>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className='bg-primary p-5 rounded-md'>
              <h3 className='font-oswald text-3xl text-center lg:text-left text-alternative mb-5'>Sobre mí</h3>
              <p className='font-dm-sans text-alternative text-center lg:text-left'>Soy un <strong className='text-accent'>ingeniero en informática</strong>, venezolano. Con experiencia de mas de 8 años en el mundo del <strong className='text-accent'>desarrollo web</strong> creando desde sistemas complejos hasta sitios web sencillos. Los ultimos 3 años de mi carrera, han sido dedicado al desarrollo del comercio electrónico llevando a un nivel más alto el alcance de mis clientes usando plataformas como Wordpress y Woocommerce, tambien Shopify.</p>
            </div>
            <div className=''>
              <h3 className='font-oswald text-3xl text-center lg:text-left text-primary mb-5'>Habilidades</h3>
              <Skills />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutMe;