import React from "react";
import { Link } from "react-router-dom";
import ComponentContactForm from "../components/contact-form";

const PageContact = () => {
  return (
    <>
      <main className="w-full h-[calc(100vh_-_120px)]">
        <div className="w-full h-full grid grid-cols-1 lg:grid-cols-2 justify-between">
          <div className="h-full w-full bg-secondary flex flex-col items-center justify-center p-5 md:p-20">
            <h1 className="block lg:hidden text-6xl text-center font-bold text-white">
              Contáctame
            </h1>
            <div className="flex gap-3 lg:hidden my-3">
              <Link
                className="bg-accent text-alternative inline-block p-3 rounded-sm"
                target="_blank"
                to="https://www.facebook.com/devjsus"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
              <Link
                className="bg-accent text-alternative inline-block p-3 rounded-sm"
                target="_blank"
                to="https://www.youtube.com/channel/UCXJA6wXh2GJxrjkUQa_XgRw"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 7H19C19.5523 7 20 7.44771 20 8V16C20 16.5523 19.5523 17 19 17H5C4.44772 17 4 16.5523 4 16V8C4 7.44772 4.44772 7 5 7ZM2 8C2 6.34315 3.34315 5 5 5H19C20.6569 5 22 6.34315 22 8V16C22 17.6569 20.6569 19 19 19H5C3.34315 19 2 17.6569 2 16V8ZM10 9L14 12L10 15V9Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
              <Link
                className="bg-accent text-alternative inline-block p-3 rounded-sm"
                target="_blank"
                to="https://wa.me/573104324212"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-brand-whatsapp"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                  <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
                </svg>
              </Link>
            </div>
            <ComponentContactForm />
          </div>
          <div className="hidden h-full w-full lg:flex flex-col items-center justify-center bg-alternative">
            <div className="w-full">
              <h1 className="text-6xl text-center font-bold">Contáctame</h1>
              <div className="hidden gap-3 lg:flex justify-center my-3">
                <Link
                  className="bg-accent text-alternative inline-block p-3 rounded-sm"
                  target="_blank"
                  to="https://www.facebook.com/devjsus"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
                <Link
                  className="bg-accent text-alternative inline-block p-3 rounded-sm"
                  target="_blank"
                  to="https://www.youtube.com/channel/UCXJA6wXh2GJxrjkUQa_XgRw"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5 7H19C19.5523 7 20 7.44771 20 8V16C20 16.5523 19.5523 17 19 17H5C4.44772 17 4 16.5523 4 16V8C4 7.44772 4.44772 7 5 7ZM2 8C2 6.34315 3.34315 5 5 5H19C20.6569 5 22 6.34315 22 8V16C22 17.6569 20.6569 19 19 19H5C3.34315 19 2 17.6569 2 16V8ZM10 9L14 12L10 15V9Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
                <Link
                  className="bg-accent text-alternative inline-block p-3 rounded-sm"
                  target="_blank"
                  to="https://wa.me/573104324212"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-brand-whatsapp"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                    <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PageContact;
