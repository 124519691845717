import { contentfulClient } from "./connect";
import { range } from "../../utils/range";


export const getCourses = async ({ request }) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 0;
  const limitQ = url.searchParams.get('limit') || 10;
  const q = url.searchParams.get('q') || '';
  

  const { items, total, skip, includes } = await contentfulClient.getEntries({
    content_type: 'curso',
    limit: limitQ,
    skip: page,
    "fields.title[match]": q
  });

  const pages = Math.floor( total / limitQ );
  
  const links = range(pages).map( (item) => {
    return {
      page: item,
      label: item + 1,
      current: skip === item
    };
  });

  return { courses: items, links, includes };
};

export const getCourse = async ({ params }) => {
  const { items } = await contentfulClient.getEntries({
    content_type: 'curso',
    "fields.slug": params.slug
  });

  if(items.length === 0) {
    throw new Response("", {
      status: 404,
      statusText: "Página no encontrada",
    });
  };

  const [course] = items;

  return { course };
};