import React from 'react';
import { Link } from 'react-router-dom';

const Drawer = ({ items, open, setOpen, validateRoute }) => {
  return (
    <>
      <div className={`w-full h-screen bg-secondary p-3 top-0 fixed z-50 transition-all ease-in-out duration-700 ${(open) ? 'right-0' : 'right-[-200%]'}`}>
        <header className='mb-5 flex items-center justify-end'>
          <button onClick={() => setOpen(false)} className='block p-3 rounded-sm bg-alternative text-accent'>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
          </button>
        </header>
        <div className="">
          <ul className='flex flex-col gap-3'>
            {( items?.length > 0 ) && (items?.map(({ fields: { href, text }, sys: { id } }) => {
              
              return (
                <li className='border-b py-2 border-b-white w-full' key={ id }>
                  <Link reloadDocument className={`text-3xl font-dm-sans inline-block font-bold ${( validateRoute(href) ) ? 'text-primary' : 'text-white'}`} to={ href }>{ text }</Link>
                </li>
              );
            }))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Drawer;