import React from 'react';
import SocialMedia from './social-media';

const Sidebar = () => {
  return (
    <>
      <div className='rounded-md text-white py-3 px-5 w-full lg:min-h-screen bg-primary'>
        <SocialMedia />
      </div>
    </>
  );
};

export default Sidebar;